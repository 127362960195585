import axios from 'axios';

export const apiRequest = async ({ url, method = 'GET', params = {}, headers = {} }) => {
  try {
    const response = await axios({
      url,
      method,
      params,
      headers: {
        'accept': 'application/json',
        ...headers,
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
    throw error;
  }
};
