import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Login from './components/Login';
import Patient from './components/Patient';
import PdfViewer from './components/PdfViewer';
import Footer from './components/Footer';
import { Box } from '@mui/material';
import { useConfig } from './hooks/useConfig'; // Импортираме useConfig

function App() {
  const location = useLocation();
  const config = useConfig(); // Зареждаме конфигурацията

  useEffect(() => {
    if (config?.pageTitle) {
      document.title = config.pageTitle; // Задаваме заглавието на страницата
    }
  }, [config]);

  // Добавяме useEffect за предотвратяване на мащабирането на страницата
  useEffect(() => {
    const preventZoom = (event) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    };

    document.addEventListener('touchmove', preventZoom, { passive: false });

    return () => {
      document.removeEventListener('touchmove', preventZoom);
    };
  }, []);

  return (
    <Box 
      display="flex" 
      flexDirection="column" 
      justifyContent="space-between" 
      minHeight="100vh"
    >
      <Box flexGrow={1} display="flex" alignItems="center" justifyContent="center">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/patient" element={<Patient />} />
          <Route path="/pdf-viewer" element={<PdfViewer />} />
        </Routes>
      </Box>
      {location.pathname !== '/pdf-viewer' && <Footer />}
    </Box>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
