import React, { useEffect, useState, useCallback } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { apiRequest } from '../utils/apiHelper';
import Header from './Header';  // Импортираме Header компонента
import LabResultsWithFilters from './LabResultsWithFilters'; // Импортираме компонента за лабораторни резултати с филтри
import { useConfig } from '../hooks/useConfig';  // Импортираме hook-а за конфигурация

// Custom hook за управление на данните на пациента чрез четене на токена
const usePatientData = () => {
  const [patientId, setPatientId] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token'); // Извличане на токена от localStorage
    if (token) {
      const tokenParts = token.split('.'); // Разделяме токена на части (Header, Payload, Signature)
      if (tokenParts.length === 3) {
        const payload = JSON.parse(atob(tokenParts[1])); // Декодиране на Payload частта на токена
        setPatientId(payload.usr); // Задаване на ID на пациента от payload-а на токена
      }
    }
  }, []);

  return patientId; // Връща ID на пациента
};

// Custom hook за извличане на лабораторни резултати от API-то
const useLabResults = (config, patientId) => {
  const [labResults, setLabResults] = useState([]); // Състояние за съхранение на лабораторните резултати
  const [loading, setLoading] = useState(false); // Състояние за индикация на зареждане

  // Функция за извличане на лабораторните резултати
  const fetchLabResults = useCallback(async () => {
    if (!config || !patientId) return; // Ако няма конфигурация или пациентско ID, не правим заявка
    const token = localStorage.getItem('token'); // Извличане на токена от localStorage

    try {
      setLoading(true); // Започваме зареждането
      const data = await apiRequest({
        url: `${config.ULRApi}/LabResultsService/GetLabResultEliz`, // URL за заявката към API-то
        params: { IDPacient: patientId }, // Параметри за заявката, включващи ID на пациента
        headers: { 'Authorization': `Bearer ${token}` } // Авторизация чрез Bearer токен
      });

      setLabResults(data); // Задаване на извлечените данни в състоянието
    } catch (error) {
      console.error('Error fetching lab results:', error); // Обработка на грешка при заявката
    } finally {
      setLoading(false); // Спираме индикацията за зареждане
    }
  }, [config, patientId]);

  // Извикваме функцията за извличане на данни при първоначално зареждане и при промяна на зависимостите
  useEffect(() => {
    fetchLabResults();
  }, [fetchLabResults]);

  return { labResults, loading, setLabResults, fetchLabResults }; 
};

// Главен компонент на страницата на пациента
export default function Patient() {
  const navigate = useNavigate(); // Hook за навигация между страниците
  const config = useConfig(); // Извличане на конфигурация чрез custom hook
  const patientId = usePatientData(); // Извличане на ID на пациента чрез custom hook
  const { labResults, loading, setLabResults, fetchLabResults } = useLabResults(config, patientId); // Управление на състоянието за лабораторни резултати

  const [currentPage, setCurrentPage] = useState(0); // Състояние за текуща страница на таблицата
  const [resultsPerPage, setResultsPerPage] = useState(5); // Състояние за брой резултати на страница
  const [selectedLab, setSelectedLab] = useState(''); // Състояние за избрана лаборатория от филтрите
  const [dateFrom, setDateFrom] = useState(new Date()); // Състояние за начална дата на филтъра
  const [dateTo, setDateTo] = useState(new Date()); // Състояние за крайна дата на филтъра

  // Опции за избиране на лаборатория от филтрите
  const labOptions = [
    'Клинична',
    'Микробиология',
    'Цитология',
    'Патология',
    'Образна',
    'Имунохематология',
    'Изследване'
  ];

  // При първоначално зареждане на страницата задаваме начална и крайна дата
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setDateFrom(new Date(currentYear, 0, 1)); // Начало на годината
    setDateTo(new Date(currentYear, 11, 31)); // Край на годината
  }, []);

  // Функция за смяна на страницата в таблицата
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // Функция за смяна на броя резултати на страница
  const handleResultsPerPageChange = (event) => {
    setResultsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0); // Рестартиране на страницата при смяна на броя резултати
  };

  // Функция за преглед на конкретен лабораторен резултат
  const handleViewResult = async (id) => {
    const token = localStorage.getItem('token'); // Извличане на токена от localStorage
    try {
      const data = await apiRequest({
        url: `${config.ULRApi}/LabResultsService/GetLabResultElizPdf`, // URL за заявката към API-то за получаване на PDF
        params: { IDDOC: id }, // Параметри на заявката (ID на документа)
        headers: { 'Authorization': `Bearer ${token}` } // Авторизация чрез Bearer токен
      });

      const base64Pdf = data[0].DocumentPdf; // Извличане на PDF документ от отговора
      navigate('/pdf-viewer', { state: { base64Data: base64Pdf } }); // Навигиране към PDF viewer със съответния PDF документ
    } catch (error) {
      console.error('Error fetching document PDF:', error); // Обработка на грешка при заявката
    }
  };

  // Функция за показване на филтрираните лабораторни резултати
  const handleShowResults = async () => {
    // Преобразуване на името на лабораторията в съответния код
    const labToTipDoc = {
      'Клинична': 1,
      'Микробиология': 2,
      'Цитология': 3,
      'Патология': 4,
      'Образна': 5,
      'Имунохематология': 10,
      'Изследване': 99,
    };

    const tipDoc = labToTipDoc[selectedLab] || 0; // Задаване на кода за типа документ

    if (!config) {
      console.error("Не е зареден конфигурационен файл.");
      return;
    }

    const token = localStorage.getItem('token'); // Извличане на токена от localStorage

    try {
      // Форматиране на датите във формат дд.мм.гггг
      const formattedDateFrom = dateFrom.toLocaleDateString('bg-BG', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }).replace(/\sг\./, ''); // Премахване на ненужните символи

      const formattedDateTo = dateTo.toLocaleDateString('bg-BG', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }).replace(/\sг\./, '');

      // Заявка към API-то за извличане на филтрираните резултати
      const data = await apiRequest({
        url: `${config.ULRApi}/LabResultsService/GetLabResultElizSearch`,
        params: {
          IDPacient: patientId, // ID на пациента
          TipDoc: tipDoc,       // Тип на документа
          DateOt: formattedDateFrom, // Начална дата
          DataDo: formattedDateTo,   // Крайна дата
        },
        headers: { 'Authorization': `Bearer ${token}` } // Авторизация чрез Bearer токен
      });

      setLabResults(data); // Задаване на филтрираните данни в състоянието
    } catch (error) {
      console.error('Error fetching lab results:', error); // Обработка на грешка при заявката
    }
  };

  // Функция за изчистване на филтрите
  const handleClearFilters = () => {
    setSelectedLab(''); // Изчистване на избраната лаборатория
    setDateFrom(new Date(new Date().getFullYear(), 0, 1)); // Задаване на начална дата като началото на годината
    setDateTo(new Date(new Date().getFullYear(), 11, 31)); // Задаване на крайна дата като края на годината
    fetchLabResults(); // Повторно зареждане на всички резултати
  };

  // Функция за изход от профила
  const handleLogout = () => {
    localStorage.clear(); // Изчистване на localStorage
    sessionStorage.clear(); // Изчистване на sessionStorage
    navigate('/'); // Навигиране към началната страница
    window.location.reload(); // Презареждане на страницата
  };

  // Показване на индикация за зареждане, докато конфигурацията се зарежда
  if (!config) {
    return <CircularProgress sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} />;
  }

  // Рендиране на компонента
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ padding: '20px', width: '100%', flexGrow: 1, overflow: 'auto' }}
    >
      <Header handleLogout={handleLogout} /> {/* Включване на Header в страницата на пациента */}

      {/* Показване на индикация за зареждане по време на заявка */}
      {loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1000, backgroundColor: 'rgba(255, 255, 255, 0.7)' }}
        >
          <CircularProgress />
        </Box>
      )}

      {/* Рендиране на компонент за лабораторни резултати с филтри */}
      <LabResultsWithFilters
        labResults={labResults} // Предаване на лабораторните резултати
        currentPage={currentPage} // Предаване на текущата страница
        resultsPerPage={resultsPerPage} // Предаване на броя резултати на страница
        handlePageChange={handlePageChange} // Функция за смяна на страницата
        handleResultsPerPageChange={handleResultsPerPageChange} // Функция за смяна на броя резултати на страница
        handleViewResult={handleViewResult} // Функция за преглед на конкретен резултат
        loading={loading} // Предаване на състоянието за зареждане
        selectedLab={selectedLab} // Предаване на избраната лаборатория
        dateFrom={dateFrom} // Предаване на началната дата
        dateTo={dateTo} // Предаване на крайната дата
        labOptions={labOptions} // Предаване на опциите за избор на лаборатория
        setSelectedLab={setSelectedLab} // Функция за задаване на избрана лаборатория
        setDateFrom={setDateFrom} // Функция за задаване на начална дата
        setDateTo={setDateTo} // Функция за задаване на крайна дата
        handleShowResults={handleShowResults} // Функция за показване на филтрираните резултати
        handleClearFilters={handleClearFilters} // Функция за изчистване на филтрите
        sx={{
          width: '100%',
          maxHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          overflow: 'auto',
        }}
      />
    </Box>
  );
}
