import React, { useState } from 'react';
import { TextField, Button, Box, CircularProgress, Grid, Typography, Card, CardContent, Paper, Divider, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { useConfig } from '../hooks/useConfig'; // Импортирай useConfig hook-а

// Styled на TextField, за да премахнем жълтото оцветяване
const StyledTextField = styled(TextField)({
  '& input:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0 1000px white inset',
    WebkitTextFillColor: 'black',
  },
});

// Компонент за съобщение за грешка
const ErrorMessage = ({ message }) => (
  <Typography color="error" sx={{ marginTop: 2, fontSize: '14px', fontWeight: 'bold' }}>
    {message}
  </Typography>
);

// Компонент за формата за вход
const LoginForm = ({ id, setId, password, setPassword, error, handleLogin, loading, apiError, buttonColors }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ p: 2 }}>
      <StyledTextField
        label="ID номер"
        variant="outlined"
        fullWidth
        value={id}
        onChange={(e) => setId(e.target.value)}
        error={!!error && !id}
        helperText={error && !id ? 'Моля въведете ID номер' : ''}
        margin="normal"
        sx={{ fontSize: 14 }}
      />
      <StyledTextField
        label="Парола"
        variant="outlined"
        type={showPassword ? 'text' : 'password'}
        fullWidth
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        error={!!error && !password}
        helperText={error && !password ? 'Моля въведете парола' : ''}
        margin="normal"
        sx={{ fontSize: 14 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant="contained"
        fullWidth
        onClick={handleLogin}
        disabled={loading}
        sx={{
          marginTop: 2,
          padding: 1.5,
          borderRadius: 3,
          boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
          backgroundColor: buttonColors.default, // Цвят по подразбиране от конфигурацията
          '&:hover': {
            backgroundColor: buttonColors.hover, // Цвят при hover от конфигурацията
          },
          '&:active': {
            backgroundColor: buttonColors.active,  // Цвят при клик от конфигурацията
          }
        }}
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Вход'}
      </Button>
      {apiError && <ErrorMessage message={apiError} />}
    </Box>
  );
};

export default function Login() {
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [apiError, setApiError] = useState('');

  const config = useConfig(); // Зареждаме конфигурационните данни чрез useConfig
  const navigate = useNavigate();

  const handleLogin = async () => {
    setApiError('');
    if (!id) {
      setError('Моля въведете ID номер');
      return;
    }
    if (!password) {
      setError('Моля въведете парола');
      return;
    }

    setError('');
    setLoading(true);

    try {
      const response = await axios.post(
        `${config.ULRApi}/LoginService/Login`,
        { username: id, password },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (response.data.value) {
        localStorage.setItem('token', response.data.value);
        navigate('/patient');
      } else {
        setApiError('Неуспешен вход');
      }
    } catch (error) {
      setApiError('Неуспешен вход');
    } finally {
      setLoading(false);
    }
  };

  if (!config) {
    return <CircularProgress sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} />;
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: '80vh', p: 2 }}
    >
      <Grid item xs={12} sm={10} md={6} lg={4}>
        <Paper elevation={12} sx={{ borderRadius: 4, overflow: 'hidden' }}>
          <Card>
            <CardContent>
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <img src={config.logoPath} alt="Logo" style={{ width: '100px', marginBottom: '20px' }} />
                <Typography variant="h5" gutterBottom>Вход в системата</Typography>
                <Typography variant="subtitle2" gutterBottom color="textSecondary" sx={{ fontSize: 14 }}>Моля, въведете вашите данни</Typography>
                <Divider sx={{ width: '100%', marginBottom: 2 }} />
                <LoginForm
                  id={id}
                  setId={setId}
                  password={password}
                  setPassword={setPassword}
                  error={error}
                  handleLogin={handleLogin}
                  loading={loading}
                  apiError={apiError}
                  buttonColors={config.buttonColors}  // Подаваме цветовете на бутона като пропс
                />
              </Box>
            </CardContent>
          </Card>
        </Paper>
      </Grid>
    </Grid>
  );
}
