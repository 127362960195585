import React from 'react';
import { Box, Typography, Link, Grid, Paper, Divider } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { useConfig } from '../hooks/useConfig'; // Импортирай useConfig hook-а

export default function Footer() {
  const config = useConfig(); // Зареждаме конфигурационните данни чрез useConfig

  if (!config) {
    return null; // Ако конфигурацията не е заредена, не рендираме нищо
  }

  return (
    <Paper elevation={3} style={{ padding: '10px', marginTop: '20px', backgroundColor: '#f5f5f5' }}>
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12}>
          <Link href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(config.address)}`} target="_blank">
            <Box display="flex" alignItems="center" justifyContent="center">
              <LocationOnIcon style={{ marginRight: '4px', fontSize: '16px' }} />
              <Typography 
                variant="body2" 
                sx={{ 
                  fontSize: { xs: '10px', sm: '12px', md: '14px' } 
                }}
              >
                {config.address}
              </Typography>
            </Box>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Link href={`tel:${config.phone}`}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <PhoneIcon style={{ marginRight: '4px', fontSize: '16px' }} />
              <Typography 
                variant="body2" 
                sx={{ 
                  fontSize: { xs: '10px', sm: '12px', md: '14px' } 
                }}
              >
                {config.phone}
              </Typography>
            </Box>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Link href={`mailto:${config.email}`}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <EmailIcon style={{ marginRight: '4px', fontSize: '16px' }} />
              <Typography 
                variant="body2" 
                sx={{ 
                  fontSize: { xs: '10px', sm: '12px', md: '14px' } 
                }}
              >
                {config.email}
              </Typography>
            </Box>
          </Link>
        </Grid>
      </Grid>
      <Divider style={{ marginTop: '10px' }} />
      <Typography 
        variant="body2" 
        color="textSecondary" 
        align="center" 
        sx={{ 
          marginTop: '10px',
          fontSize: { xs: '10px', sm: '12px', md: '14px' } 
        }}
      >
        &copy; {new Date().getFullYear()} <Link href="https://new.gss.bg/" target="_blank" color="inherit">Global System Solutions Ltd.</Link>. Всички права запазени.
      </Typography>
    </Paper>
  );
}
