import React, { useEffect } from 'react';
// Импортиране на компоненти от Material UI и други библиотеки
import {
  Grid, Select, MenuItem, Button, TextField, Box, Card, CardContent, Typography,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, IconButton
} from '@mui/material';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import { Bulgarian } from 'flatpickr/dist/l10n/bg.js';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useConfig } from '../hooks/useConfig';

// Дефиниране на breakpoints за отзивчив дизайн
const BREAKPOINTS = {
  mobile: '960px',
};

// Основната функция на компонента
export default function LabResultsWithFilters({
  labResults,                // Лабораторни резултати
  currentPage,               // Текуща страница
  resultsPerPage,            // Брой резултати на страница
  handlePageChange,          // Функция за смяна на страницата
  handleResultsPerPageChange,// Функция за смяна на броя резултати на страница
  handleViewResult,          // Функция за преглед на конкретен резултат
  loading,                   // Състояние на зареждане (true/false)
  selectedLab,               // Избрана лаборатория
  dateFrom,                  // Начална дата за филтриране
  dateTo,                    // Крайна дата за филтриране
  labOptions,                // Опции за избор на лаборатория
  setSelectedLab,            // Функция за задаване на избрана лаборатория
  setDateFrom,               // Функция за задаване на начална дата
  setDateTo,                 // Функция за задаване на крайна дата
  handleShowResults,         // Функция за показване на филтрираните резултати
  handleClearFilters,        // Функция за изчистване на филтрите
}) {
  const config = useConfig(); // Извличане на конфигурация чрез hook

  // Използване на useEffect за автоматично задаване на начална и крайна дата при първоначално зареждане
  useEffect(() => {
    const currentYear = new Date().getFullYear(); // Текущата година
    const startOfYear = new Date(currentYear, 0, 1); // Начало на годината
    const endOfYear = new Date(currentYear, 11, 31); // Край на годината

    setDateFrom(startOfYear); // Задаване на начална дата
    setDateTo(endOfYear);     // Задаване на крайна дата
  }, [setDateFrom, setDateTo]); // Зависи от setDateFrom и setDateTo

  // Изчисляване на индекси за рязане на резултатите за текущата страница
  const indexOfLastResult = (currentPage + 1) * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;

  // Изрязване на текущите резултати, които да се покажат на страницата
  const currentResults = labResults.slice(indexOfFirstResult, indexOfLastResult);

  // Проверка дали конфигурацията е заредена
  if (!config) {
    return null; // Ако не е заредена, не показваме нищо
  }

  // Рендиране на компонента
  return (
    <Box sx={{ padding: { xs: '10px', sm: '20px' }, maxWidth: '100%' }}>
      {/* Карта съдържаща формата за филтриране */}
      <Card sx={{ boxShadow: 3, borderRadius: 2, marginBottom: '20px' }}>
        <CardContent>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
            Филтри
          </Typography>
          <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
            {/* Меню за избор на лаборатория */}
            <Grid item xs={12} md={12} lg={3}>
              <Select
                value={selectedLab} // Избрана стойност
                onChange={(e) => setSelectedLab(e.target.value)} // Смяна на избора
                displayEmpty
                fullWidth
                variant="outlined"
                sx={{
                  borderRadius: 2,
                  backgroundColor: 'white',
                  boxShadow: 1,
                  fontSize: {
                    xs: '0.875rem',
                    sm: '1rem',
                  },
                  height: '56px', // Височината да е същата като на TextField
                }}
                aria-label="Избери"
              >
                <MenuItem value="">
                  <span>Избери</span>
                </MenuItem>
                {labOptions.map((lab) => (
                  <MenuItem key={lab} value={lab}>
                    {lab}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            {/* Начална дата и Крайна дата една до друга */}
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Flatpickr
                    value={dateFrom} // Начална дата
                    onChange={(date) => setDateFrom(date[0])} // Смяна на началната дата
                    options={{
                      dateFormat: 'd.m.Y', // Формат на датата
                      locale: Bulgarian,    // Локализация на Flatpickr на български
                      disableMobile: true,  // Забранява мобилния интерфейс на Flatpickr
                    }}
                    render={({ defaultValue, value, ...props }, ref) => (
                      <TextField
                        {...props}
                        inputRef={ref}
                        value={defaultValue}
                        label="Начална дата"
                        variant="outlined"
                        fullWidth
                        sx={{
                          borderRadius: 2,
                          backgroundColor: 'white',
                          boxShadow: 1,
                          fontSize: {
                            xs: '0.875rem',
                            sm: '1rem',
                          },
                        }}
                      />
                    )}
                    aria-label="Начална дата"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Flatpickr
                    value={dateTo} // Крайна дата
                    onChange={(date) => setDateTo(date[0])} // Смяна на крайната дата
                    options={{
                      dateFormat: 'd.m.Y', // Формат на датата
                      locale: Bulgarian,    // Локализация на Flatpickr на български
                      disableMobile: true,  // Забранява мобилния интерфейс на Flatpickr
                    }}
                    render={({ defaultValue, value, ...props }, ref) => (
                      <TextField
                        {...props}
                        inputRef={ref}
                        value={defaultValue}
                        label="Крайна дата"
                        variant="outlined"
                        fullWidth
                        sx={{
                          borderRadius: 2,
                          backgroundColor: 'white',
                          boxShadow: 1,
                          fontSize: {
                            xs: '0.875rem',
                            sm: '1rem',
                          },
                        }}
                      />
                    )}
                    aria-label="Крайна дата"
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Бутоните за показване и изчистване на резултати */}
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleShowResults} // Показва резултатите при клик
                    fullWidth
                    sx={{
                      height: '56px', // Височината да съвпада с другите компоненти
                      borderRadius: 2,
                      fontSize: {
                        xs: '0.875rem',
                        sm: '1rem',
                      },
                    }}
                    startIcon={<SearchIcon />} // Икона за търсене
                    aria-label="Покажи резултати"
                  >
                    Покажи
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleClearFilters} // Изчиства филтрите при клик
                    fullWidth
                    sx={{
                      height: '56px', // Височината да съвпада с другите компоненти
                      borderRadius: 2,
                      fontSize: {
                        xs: '0.875rem',
                        sm: '1rem',
                      },
                    }}
                    startIcon={<ClearIcon />} // Икона за изчистване
                    aria-label="Изчисти филтрите"
                  >
                    Изчисти
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Таблица с резултати */}
      <TableContainer component={Paper} sx={{ overflowX: 'auto', '&::-webkit-scrollbar': { display: 'none' }, boxShadow: 3, borderRadius: 2 }}>
        <Table aria-label="lab results table">
          <TableHead>
            <TableRow sx={{ backgroundColor: config.tableHeaderColor }}>
              <TableCell sx={{ fontWeight: 'bold', width: '20%', padding: '8px' }}>Дата</TableCell>
              <TableCell sx={{ fontWeight: 'bold', width: '60%', padding: '8px' }}>Описание</TableCell>
              <TableCell sx={{ fontWeight: 'bold', width: '20%', padding: '8px' }} align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Попълване на данните в таблицата */}
            {/* currentResults съдържа резултатите, които се показват на текущата страница
             Те се извеждат в таблицата чрез .map(), който създава редове за всеки резултат */}
            {currentResults.map((result) => (
              <TableRow
                key={result.Id}
                sx={{
                  [`@media (max-width: ${BREAKPOINTS.mobile})`]: {
                    flexWrap: 'wrap',
                  },
                }}
              >
                {/* Показване на датата на промяна за текущия резултат */}
                <TableCell sx={{ padding: '8px' }}>{result.DateChange}</TableCell>

                {/* Показване на описанието за текущия резултат */}
                <TableCell
                  sx={{
                    wordBreak: 'break-word',   // Прекъсва дълги думи
                    whiteSpace: 'normal',      // Позволява пренасяне на текст
                    textOverflow: 'ellipsis',  // Показва многоточие за дълги текстове
                    maxWidth: '300px',
                    padding: '8px',
                    [`@media (max-width: ${BREAKPOINTS.mobile})`]: {
                      maxWidth: '100%',
                    },
                  }}
                >
                  {result.Opis}
                </TableCell>
                
                <TableCell
                  align="right"
                  sx={{
                    padding: '10px',
                    [`@media (max-width: ${BREAKPOINTS.mobile})`]: {
                      width: '100%',
                      textAlign: 'center',
                      marginTop: '8px',
                    },
                  }}
                >
                  {/* Бутон за преглед на конкретен резултат */}
                  <Button
                    variant="contained"
                    onClick={() => handleViewResult(result.Id)} // Преглед на конкретен резултат
                    disabled={loading} // Деактивиране на бутона при зареждане
                    sx={{
                      fontSize: {
                        xs: '0.75rem',
                        sm: '0.875rem',
                        md: '1rem',
                      },
                      padding: { xs: '4px 8px', sm: '6px 12px', md: '8px 16px' },
                      backgroundColor: config.buttonColors.default,
                      '&:hover': {
                        backgroundColor: config.buttonColors.hover,
                      },
                      '&:active': {
                        backgroundColor: config.buttonColors.active,
                      },
                    }}
                    aria-label={`Виж резултат за ${result.Opis}`}
                  >
                    Виж резултат
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={labResults.length} // Общ брой резултати
          page={currentPage}        // Текуща страница
          onPageChange={handlePageChange} // Смяна на страницата
          rowsPerPage={resultsPerPage}    // Резултати на страница
          onRowsPerPageChange={handleResultsPerPageChange} // Смяна на броя резултати на страница
          rowsPerPageOptions={[5, 10, 15, 20]} // Опции за броя на резултатите на страница
          labelRowsPerPage="Покажи"
          labelDisplayedRows={() => null} // Скриване на стандартния текст "Страница X от Y"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& .MuiTablePagination-toolbar': {
              justifyContent: 'center',
            },
            '& .MuiTablePagination-caption': {
              margin: '0 10px',
            },
            '& .MuiTablePagination-actions': {
              display: 'flex',
              justifyContent: 'center',
              order: 1,
            },
            '& .MuiTablePagination-spacer': {
              display: 'none',
            },
          }}
          // Къстъмизация на бутоните за странициране
          ActionsComponent={({ count, page, rowsPerPage, onPageChange }) => (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                onClick={(event) => onPageChange(event, page - 1)} // Предишна страница
                disabled={page === 0} // Деактивиране на бутона, ако е на първата страница
                aria-label="Предишна страница"
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="body2" sx={{ margin: '0 10px' }}>
                {page + 1} / {Math.ceil(count / rowsPerPage)} 
              </Typography>
              <IconButton
                onClick={(event) => onPageChange(event, page + 1)} // Следваща страница
                disabled={page >= Math.ceil(count / rowsPerPage) - 1} // Деактивиране на бутона, ако е на последната страница
                aria-label="Следваща страница"
              >
                <ArrowForwardIcon />
              </IconButton>
            </Box>
          )}
        />
      </TableContainer>
    </Box>
  );
}
