import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Header from './Header';
import { useConfig } from '../hooks/useConfig';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

const MOBILE_MAX_WIDTH = '959px';
const DESKTOP_MIN_WIDTH = '960px';

const CloseButton = ({ onClick, colors }) => (
    <IconButton
        onClick={onClick}
        sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            zIndex: 1000,
            width: 32,
            height: 32,
            bgcolor: colors.background,
            color: colors.color,
            borderRadius: 0,
            '&:hover': {
                bgcolor: colors.hoverBackground,
            },
            boxShadow: 'none',
            [`@media (max-width: ${MOBILE_MAX_WIDTH})`]: {
                bgcolor: colors.background,
                color: colors.color,
            },
        }}
    >
        <CloseIcon />
    </IconButton>
);

const PdfViewerComponent = ({ base64Data, colors, defaultScale }) => {
    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar } = toolbarPluginInstance;

    const transformToolbar = (slot) => ({
        ...slot,
        Open: () => <></>,
        OpenMenuItem: () => <></>,
    });

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar: (Toolbar) => (
            <Toolbar>
                {renderDefaultToolbar(transformToolbar)}
            </Toolbar>
        )
    });

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                borderRadius: '8px',
                overflow: 'auto',
                bgcolor: colors.background,
                [`@media (max-width: ${MOBILE_MAX_WIDTH})`]: {
                    height: '100%',
                    borderRadius: 0,
                    bgcolor: colors.background,
                },
                [`@media (min-width: ${DESKTOP_MIN_WIDTH})`]: {
                    margin: '20px',
                }
            }}
        >
            <Viewer
                fileUrl={`data:application/pdf;base64,${base64Data}`}
                plugins={[defaultLayoutPluginInstance, toolbarPluginInstance]}
                defaultScale={defaultScale}
                style={{
                    height: '100%',
                    width: '100%',
                }}
            />
        </Box>
    );
};

export default function PdfViewer() {
    const location = useLocation();
    const navigate = useNavigate();
    const { base64Data } = location.state || {};

    const config = useConfig();

    const handleLogout = () => {
        localStorage.clear();
        sessionStorage.clear();
        navigate('/');
    };

    const [defaultScale, setDefaultScale] = useState(1.0);

    useEffect(() => {
        const handleResize = () => {
            setDefaultScale(window.innerWidth < 960 ? 0.5 : 1.0);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        window.scrollTo(0, 0);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (!config) {
        return null;
    }

    const viewerConfig = window.innerWidth < 960 ? config.pdfViewer.mobile : config.pdfViewer.desktop;

    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Box
                sx={{
                    height: '100vh',
                    width: '100vw',
                    display: 'flex',
                    flexDirection: 'column',
                    bgcolor: viewerConfig.layout.background,
                    padding: 0,
                    position: 'relative',
                    boxSizing: 'border-box',
                }}
            >
                <Header handleLogout={handleLogout} />
                <Box
                    sx={{
                        flexGrow: 1,
                        bgcolor: viewerConfig.layout.pdfContainerBackground,  
                        borderRadius: '8px 8px 0 0',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 2,
                        paddingRight: 6,
                        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                        overflow: 'hidden',
                        position: 'relative',
                        height: 'calc(100% - 40px)',
                        margin: '20px 20px 0 20px',
                        [`@media (max-width: ${MOBILE_MAX_WIDTH})`]: {
                            bgcolor: 'transparent',
                            padding: 0,
                            margin: 0,
                            display: 'block',
                        },
                    }}
                >
                    <CloseButton onClick={() => navigate('/patient')} colors={viewerConfig.closeButton} />
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            [`@media (max-width: ${MOBILE_MAX_WIDTH})`]: {
                                width: '100%',
                                height: 'calc(100vh - 50px)',
                                padding: 0,
                                marginTop: '50px',
                                bgcolor: viewerConfig.pdfViewer.background,
                            },
                            [`@media (min-width: ${DESKTOP_MIN_WIDTH})`]: {
                                margin: '20px',
                            }
                        }}
                    >
                        {base64Data ? (
                            <PdfViewerComponent
                                base64Data={base64Data}
                                colors={viewerConfig.pdfViewer}
                                defaultScale={defaultScale}
                            />
                        ) : (
                            <Box sx={{ color: '#ffffff', textAlign: 'center' }}>
                                Няма налични PDF данни
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </Worker>
    );
}
