import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Avatar, Menu, MenuItem, Box } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useConfig } from '../hooks/useConfig'; // Импортирай useConfig от правилното място

export default function Header({ handleLogout }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [patientName, setPatientName] = useState('');
  
  // Зареждаме конфигурационните данни чрез useConfig
  const config = useConfig();

  // Извличаме името на пациента от токена
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const tokenParts = token.split('.');
      if (tokenParts.length === 3) {
        const payload = JSON.parse(atob(tokenParts[1]));
        setPatientName(payload.pac); // Задаваме името на пациента
      }
    }
  }, []);

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  if (!config) {
    return null; // Ако конфигурацията не е заредена, не рендираме нищо
  }

  return (
    <>
      <AppBar position="fixed" sx={{ bgcolor: config.appBarColor }}>  {/* Използваме цвят за AppBar от config */}
        <Toolbar>
          <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="h6"
              component="div"
              sx={{
                fontSize: {
                  xs: '0.75rem',
                  sm: '1rem',
                  md: '1.5rem',
                },
                '@media (orientation: portrait)': {
                  fontSize: '0.60rem',
                },
                mr: 2,
                color: config.patientNameColor,  // Използваме цвят за името на пациента от config
              }}
            >
              {patientName}
            </Typography>
            <IconButton onClick={handleAvatarClick} color="inherit">
              <Avatar>
                <AccountCircleIcon />
              </Avatar>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleLogout}>Изход</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
}
